import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { getIsLookbookEnabled } from '../../ducks';
import { paths } from '../../paths';
import { useSwitchToRedesign } from '../../utils/hooks/useSwitchToRedesign';
import { EmptyState } from '../various/EmptyState';
import Icon, { IconType } from '../various/Icon';
import { Button } from '../various/NewButton';
import { PageTitle } from '../various/PageTitle';
import { TopBar } from '../various/TopBar';
import { Wrapper, WrapperSize } from '../various/Wrapper';

export const Lookbook = () => {
  const { t } = useTranslation(['lookbook', 'common', 'onboarding']);
  const isLookbookEnabled = useSelector(getIsLookbookEnabled);
  const [isSwitchButtonLoading, setIsSwitchButtonLoading] = React.useState(false);
  const switchToRedesign = useSwitchToRedesign();
  const handleSwitchButtonClick = () => {
    setIsSwitchButtonLoading(true);
    switchToRedesign();
  };

  if (!isLookbookEnabled) {
    return <Navigate to={paths.ROOT} replace />;
  }

  return (
    <PageTitle title={t('common:lookbook')}>
      <>
        <TopBar title={t('common:lookbook')} />
        <Wrapper size={WrapperSize.XXLARGE}>
          <EmptyState title={t('lookbook:deprecation.title')} subtitle={t('lookbook:deprecation.subtitle')}>
            <Button variant="bordered" color="dark" onClick={handleSwitchButtonClick}>
              {isSwitchButtonLoading ?
                <Icon type={IconType.Spinner} size={16} />
              : t('onboarding:upgrade_to_redesign')}
            </Button>
          </EmptyState>
        </Wrapper>
      </>
    </PageTitle>
  );
};
